import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAmazon } from '@fortawesome/free-brands-svg-icons/faAmazon'
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons/faGooglePlay'
import appleBooksIcon from '../svg/Apple_Books_Icon_wht.svg'
import indieBoundIcon from '../svg/indiebound-logo.svg'
import barnesAndNobleIcon from '../png/barnesnoble-384-white.png'
import indigoIcon from '../png/indigo-384-white.png'
import * as styles from './purchase.module.css'

export default ({
  amazon,
  barnesAndNoble,
  googlePlay,
  iBooks,
  indieBound,
  indigo,
}) => (
  <>
    <section className={styles.purchase}>
      <h3>Purchase</h3>
      <div className={styles.divider}></div>
      <ul className={styles.bookstores}>
        {amazon && (
          <li>
            <a href={amazon}>
              <span className={styles.name}>Amazon</span>
              <span className={styles.amazonIcon}>
                <FontAwesomeIcon icon={faAmazon} title="Amazon" />
              </span>
            </a>
          </li>
        )}
        {barnesAndNoble && (
          <li>
            <a href={barnesAndNoble}>
              <span className={styles.name}>Barnes and Noble</span>
              <span className={styles.icon}>
                <img
                  className={styles.barnesAndNobleImg}
                  src={barnesAndNobleIcon}
                  title="Barnes and Noble"
                />
              </span>
            </a>
          </li>
        )}
        {googlePlay && (
          <li>
            <a href={googlePlay}>
              <span className={styles.name}>Google Play</span>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faGooglePlay} title="Google Play" />
              </span>
            </a>
          </li>
        )}
        {iBooks && (
          <li>
            <a href={iBooks}>
              <span className={styles.name}>iBooks</span>
              <span className={styles.icon}>
                <img
                  className={styles.appleBooksImg}
                  src={appleBooksIcon}
                  title="iBooks"
                />
              </span>
            </a>
          </li>
        )}
        {indigo && (
          <li>
            <a href={indigo}>
              <span className={styles.name}>Indigo</span>
              <span className={styles.icon}>
                <img src={indigoIcon} title="Indigo" />
              </span>
            </a>
          </li>
        )}
        {indieBound && (
          <li>
            <a href={indieBound}>
              <span className={styles.name}>Indie Bound</span>
              <span className={styles.icon}>
                <img
                  className={styles.indieBoundImg}
                  src={indieBoundIcon}
                  title="Indie Bound"
                />
              </span>
            </a>
          </li>
        )}
      </ul>
    </section>
  </>
)
