// https://github.com/mattboldt/typed.js/blob/master/README.md
// https://jsfiddle.net/mattboldt/ovat9jmp/

import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import caretL from '../svg/caret-l.svg'
import caretR from '../svg/caret-r.svg'
import Typed from '../utils/typed'
import Carousel from 'react-slick'
import './slick.css'
import './slick-theme.css'

// https://react-slick.neostack.com/docs/example/custom-arrows
function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Next">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretR})`,
          }}
        ></span>
      </a>
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Previous">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretL})`,
          }}
        ></span>
      </a>
    </div>
  )
}

const carouselSettings = {
  dots: false,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

class TypedHero extends React.Component {
  listenForClose(e) {
    e = e || window.event
    // Close on ESC
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.togglePanel()
    }
  }

  togglePanel() {
    const panelClass = '.type-' + location.hash.substring(1)
    document.querySelectorAll(panelClass).forEach(function (panel) {
      panel.classList.toggle('is-active')
    })
    location.hash = ''
  }

  componentDidMount() {
    // Bind ESC key handler
    window.onkeydown = this.listenForClose.bind(this)

    // If you want to pass more options as props, simply add
    // your desired props to this destructuring assignment.
    const { strings } = this.props
    // You can pass other options here, such as typing speed, back speed, etc.
    const options = {
      strings: strings,
      typeSpeed: 50,
      backSpeed: 50,
      loop: false,
      showCursor: false, // if commented out must uncomment .typed-cursor line
      autoInsertCss: false,
      onComplete: async function (self) {
        // self.showCursor = false
        // ^ does not update cursor visibility until reset :(
        document.querySelector('.type-wrap .panel-background').style.display = 'block'
        document.querySelectorAll('.type-wrap span a').forEach(function (link) {
          link.style.paddingRight = '136px'
        })
        document.querySelector('.scrim').style.display = 'none'
        await sleep(1500)
        // document.querySelector('.typed-cursor').style.display = 'none'
      },
    }
    // this.el refers to the <span> in the render() method
    this.typed = new Typed(this.el, options)
  }

  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting to prevent memory leaks
    this.typed.destroy()
  }

  render() {
    const location = this.props.location
    const activistBackground = this.props.activistBackground
    const activistDescription = this.props.activistDescription
    const authorBackground = this.props.authorBackground
    const authorDescription = this.props.authorDescription
    const heroBackground = this.props.heroBackground
    const producerBackground = this.props.producerBackground
    const producerDescription = this.props.producerDescription
    const speakerBackground = this.props.speakerBackground
    const speakerDescription = this.props.speakerDescription

    if (location.hash) {
      const panelClass = '.type-' + location.hash.substring(1)
      document.querySelectorAll(panelClass).forEach(async function (panel) {
        panel.classList.toggle('is-active')
        // focus back button, asked to disable
        // await sleep(500)
        // panel.children[0].children[0].focus()
      })
    }

    const ClosePanelButton = () => (
      <aside>
        <button
          aria-expanded="true"
          aria-label="Close the panel"
          onClick={this.togglePanel}
        >
          <span
            className="caret"
            style={{
              backgroundImage: `url(${caretL})`,
            }}
          ></span>
        </button>
      </aside>
    )

    return (
      <div className="hero-wrapper">
        <div className="type-wrapper bkd-near-black">
          <div className="type-panels">
            <div className="type-wrap type-panel bkd-near-black">
              <div className="panel-background">
                <GatsbyImage image={heroBackground} className="panel-background-image" alt="" />
              </div>
              <div className="type-text">
                <noscript>
                  <span>
                    <a href="#author">Author</a>
                    <br />
                    <a href="#producer">Producer</a>
                    <br />
                    <a href="#speaker">Speaker</a>
                    <br />
                    <a href="#activist">Activist</a>
                  </span>
                </noscript>
                <span
                  ref={(el) => {
                  this.el = el
                  }}
                />
              </div>
              <div className="scrim"></div>
            </div>
            <div className="type-author type-panel bkd-near-black">
              <ClosePanelButton />
              <div>
                <div className="panel-background">
                  <GatsbyImage image={authorBackground} className="panel-background-image" alt="" />
                </div>
                <span className="panel-text">
                  <h3>Author</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: authorDescription,
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="type-producer type-panel bkd-near-black">
              <ClosePanelButton />
              <div>
                <div className="panel-background">
                  <GatsbyImage image={producerBackground} className="panel-background-image" alt="" />
                </div>
                <span className="panel-text">
                  <h3>Producer</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: producerDescription,
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="type-speaker type-panel bkd-near-black">
              <ClosePanelButton />
              <div>
                <div className="panel-background">
                  <GatsbyImage image={speakerBackground} className="panel-background-image" alt="" />
                </div>
                <span className="panel-text">
                  <h3>Speaker</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: speakerDescription,
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="type-activist type-panel bkd-near-black">
              <ClosePanelButton />
              <div>
                <div className="panel-background">
                  <GatsbyImage image={activistBackground} className="panel-background-image" alt="" />
                </div>
                <span className="panel-text">
                  <h3>Activist</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: activistDescription,
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-carousel">
          <Carousel {...carouselSettings}>
            <div className="hero-section type-panel bkd-near-black">
              <div className="panel-background">
                <GatsbyImage image={authorBackground} alt="" />
              </div>
              <span className="panel-text">
                <h3>Author</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: authorDescription,
                  }}
                />
              </span>
            </div>
            <div className="hero-section type-panel bkd-near-black">
              <div className="panel-background">
                <GatsbyImage image={producerBackground} alt="" />
              </div>
              <span className="panel-text">
                  <h3>Producer</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: producerDescription,
                    }}
                  />
                </span>
            </div>
            <div className="hero-section type-panel bkd-near-black">
              <div className="panel-background">
                <GatsbyImage image={speakerBackground} alt="" />
              </div>
              <span className="panel-text">
                <h3>Speaker</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: speakerDescription,
                  }}
                />
              </span>
            </div>
            <div className="hero-section type-panel bkd-near-black">
              <div className="panel-background">
                <GatsbyImage image={activistBackground} alt="" />
              </div>
              <span className="panel-text">
                <h3>Activist</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: activistDescription,
                  }}
                />
              </span>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default TypedHero
