import React from 'react'
import { graphql, Link } from 'gatsby'
import Event from './event'
import * as styles from './event-list.module.css'

export default function EventList({ events, sectionCount }) {
  const endOfDay = (date) => new Date(date).setHours(23, 59, 59, 999)

  const upcoming = events.nodes.filter(
    (event) => endOfDay(event.date) >= Date.now()
  )

  // past events are hidden below
  // const past = events.nodes
  //   .filter((event) => endOfDay(event.date) < Date.now())
  //   .reverse()

  return events.nodes.length > 0 ? (
    <section>
      <header>
        <p className="section-number">0{(sectionCount += 1)}</p>
        <h2 id="events">Events</h2>
      </header>
      {upcoming.length > 0 ? (
        <div className={styles.eventListWrapper}>
          {/* <h3>Upcoming Events</h3> */}
          <ul className={styles.eventList}>
            {upcoming.map((event) => (
              <li key={event.id}>
                <Event event={event} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ``
      )}

      {/* {past.length > 0 ? (
        <div className={styles.eventListWrapper}>
          <h3>Past Events</h3>
          <ul className={styles.eventList}>
            {past.map((event) => (
              <li key={event.id}>
                <Event event={event} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ``
      )} */}
      <div className="mt section-description">
        If you are interested in having Angie speak at your school, library,
        organization, conference or other event, stop by the{' '}
        <Link to="/faq/">FAQ</Link> then visit the{' '}
        <Link to="/contact/">Contact page</Link>.
      </div>
      <Link
        className="button mt section-description mobile-full"
        to="/contact/"
      >
        Contact
      </Link>
    </section>
  ) : (
    <section id="events">
      <header>
        <p className="section-number">0{(sectionCount += 1)}</p>
        <h2 id="events">Events</h2>
      </header>
      <p>No events are scheduled right now.</p>
      <div className="mt section-description">
        If you are interested in having Angie speak at your school, library,
        organization, conference or other event, stop by the{' '}
        <Link to="/faq/">FAQ</Link> then visit the{' '}
        <Link to="/contact/">Contact page</Link>.
      </div>
      <Link
        className="button mt section-description mobile-full"
        to="/contact/"
      >
        Contact
      </Link>
    </section>
  )
}

export const query = graphql`
  fragment AngieEvents on ContentfulEventConnection {
    nodes {
      date
      id
      ...EventFragment
    }
  }
`
