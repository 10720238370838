import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import EventList from './event-list'
import NextEvent from './next-event'

const Events = ({ nextOnly, sectionCount }) => {
  const { events } = useStaticQuery(graphql`
    query {
      events: allContentfulEvent(sort: { order: ASC, fields: [date] }) {
        ...AngieEvents
      }
    }
  `)
  return nextOnly ? (
    <NextEvent events={events} sectionCount={sectionCount} />
  ) : (
    <EventList events={events} sectionCount={sectionCount} />
  )
}

export default Events
