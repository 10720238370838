import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './event.module.css'

const displayDate = (date) =>
  date.toLocaleDateString(`en-US`, {
    year: `numeric`,
    month: `short`,
    day: `2-digit`,
    timeZone: `UTC`, // UTC+00:00 set in Contentful to avoid time zone shift
  })

function FormattedDate(date, endDate) {
  const dateString = displayDate(new Date(date))
  const splitDate = dateString.split(' ')
  if (endDate) {
    const endDateString = displayDate(new Date(endDate))
    const splitEndDate = endDateString.split(' ')
    return (
      <>
        <span className={styles.month}>{splitDate[0]}</span>
        <span className={styles.day}>
          {splitDate[1].substring(0, 2)}-{splitEndDate[1].substring(0, 2)}
        </span>
        <span className={styles.year}>{splitDate[2]}</span>
      </>
    )
  }

  return (
    <>
      <span className={styles.month}>{splitDate[0]}</span>
      <span className={styles.day}>{splitDate[1].substring(0, 2)}</span>
      <span className={styles.year}>{splitDate[2]}</span>
    </>
  )
}

const displayTime = (date) =>
  date.toLocaleTimeString(`en-US`, {
    hour: `numeric`,
    minute: `numeric`,
    timeZone: `UTC`,
    // timeZoneName: `short`, // for debugging
  })

const Event = ({
  event: {
    callToAction,
    date,
    endDate,
    endTime,
    location,
    startTime,
    timeZone,
    title,
    url,
  },
}) => (
  <React.Fragment>
    <span className={styles.dateBox}>
      {date ? FormattedDate(date, endDate) : ``}
    </span>
    <span className={styles.deets}>
      {url ? <a href={url}>{title}</a> : title}
      {startTime ? (
        <p className={styles.whenWhere}>
          {startTime ? `WHEN: ` + displayTime(new Date(startTime)) : ``}
          {endTime ? ` - ` + displayTime(new Date(endTime)) : ``}
          {timeZone ? ` ` + timeZone : ``}
        </p>
      ) : (
        ``
      )}
      {location ? (
        <p className={styles.whenWhere}>{`WHERE: ${location}`}</p>
      ) : (
        ``
      )}
    </span>
    <span className={url ? styles.cta : `${styles.cta} force-trans`}>
      {url ? (
        callToAction ? (
          <a href={url}>{callToAction}</a>
        ) : (
          <a href={url}>{`Get Your Tickets`}</a>
        )
      ) : (
        ``
      )}
    </span>
  </React.Fragment>
)

export default Event

export const query = graphql`
  fragment EventFragment on ContentfulEvent {
    callToAction
    date
    endDate
    endTime
    location
    startTime
    timeZone
    title
    url
  }
`
