import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import TypedHero from '../components/typed-hero'
import Quotes from '../components/quotes'
import Purchase from '../components/purchase'
import Events from '../components/events/events'
// import Instagram from '../components/instagram/grams'

class RootIndex extends React.Component {
  render() {
    const books = this.props.data.allContentfulBook.nodes
    const home = this.props.data.contentfulHomepage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <div>
          <TypedHero
            strings={[
              '<a href="#author">Author</a><br /><a href="#producer">Producer</a><br /><a href="#speaker">Speaker</a><br /><a href="#activist">Activist</a>',
            ]}
            location={this.props.location}
            activistBackground={home.activistBackground.gatsbyImageData}
            activistDescription={
              home.activistDescription.childMarkdownRemark.html
            }
            authorBackground={home.authorBackground.gatsbyImageData}
            authorDescription={home.authorDescription.childMarkdownRemark.html}
            heroBackground={home.heroBackground.gatsbyImageData}
            producerBackground={home.producerBackground.gatsbyImageData}
            producerDescription={
              home.producerDescription.childMarkdownRemark.html
            }
            speakerBackground={home.speakerBackground.gatsbyImageData}
            speakerDescription={
              home.speakerDescription.childMarkdownRemark.html
            }
          />
          <section>
            <header>
              <p className="section-number">0{(sectionCount += 1)}</p>
              <h2 id="about">About</h2>
            </header>
            <div
              className="mt section-description"
              dangerouslySetInnerHTML={{
                __html: home.about.childMarkdownRemark.html,
              }}
            />
            <Link
              className="button mt section-description mobile-full"
              to="/about/"
            >
              Learn More
            </Link>
          </section>
          {books[0].quotes && books[0].quoteImage && (
            <>
              <Quotes
                quotes={books[0].quotes}
                quoteImage={books[0].quoteImage.gatsbyImageData}
                sectionCount={
                  (sectionCount +=
                    books[0].quotes && books[0].quoteImage ? 1 : 0)
                }
                slug={books[0].slug}
              />
              <Purchase
                amazon={books[0].amazon}
                barnesAndNoble={books[0].barnesAndNoble}
                googlePlay={books[0].googlePlay}
                iBooks={books[0].iBooks}
                indieBound={books[0].indieBound}
                indigo={books[0].indigo}
              />
            </>
          )}
          <Events nextOnly sectionCount={sectionCount} />
          {/* <Instagram sectionCount={sectionCount + 1} /> */}
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHomepage(id: { eq: "43b9341f-bdde-5eb2-bd01-759e9dbb5aa2" }) {
      about {
        childMarkdownRemark {
          html
        }
      }
      activistBackground {
        gatsbyImageData(layout: CONSTRAINED)
      }
      activistDescription {
        childMarkdownRemark {
          html
        }
      }
      authorBackground {
        gatsbyImageData(layout: CONSTRAINED)
      }
      authorDescription {
        childMarkdownRemark {
          html
        }
      }
      heroBackground {
        gatsbyImageData(layout: CONSTRAINED)
      }
      producerBackground {
        gatsbyImageData(layout: CONSTRAINED)
      }
      producerDescription {
        childMarkdownRemark {
          html
        }
      }
      speakerBackground {
        gatsbyImageData(layout: CONSTRAINED)
      }
      speakerDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBook(
      filter: { featured: { eq: true } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      nodes {
        amazon
        barnesAndNoble
        googlePlay
        iBooks
        indieBound
        indigo
        quoteImage {
          gatsbyImageData(width: 969, layout: CONSTRAINED)
        }
        quotes {
          author
          id
          quote {
            childMarkdownRemark {
              html
            }
          }
        }
        slug
      }
    }
  }
`
