import React from 'react'
import { graphql, Link } from 'gatsby'
import Event from './event'
import * as styles from './event-list.module.css'

export default function NextEvent({ events, sectionCount }) {
  const endOfDay = (date) => new Date(date).setHours(23, 59, 59, 999)

  const upcoming = events.nodes.filter(
    (event) => endOfDay(event.date) >= Date.now()
  )

  return upcoming.length > 0 ? (
    <section>
      <header>
        <p className="section-number">0{(sectionCount += 1)}</p>
        <h2 id="events">Events</h2>
      </header>
      <ul className={styles.eventList}>
        <li key={upcoming[0].id}>
          <Event event={upcoming[0]} />
        </li>
      </ul>
      <Link className="button mt mobile-full" to="/events/#events">
        View All Events
      </Link>
    </section>
  ) : (
    <section>
      <header>
        <p className="section-number">0{(sectionCount += 1)}</p>
        <h2 id="events">Events</h2>
      </header>
      <p>No upcoming events are scheduled right now.</p>
      <Link className="button mt mobile-full" to="/events/">
        View Past Events
      </Link>
    </section>
  )
}

export const query = graphql`
  fragment NextEvents on ContentfulEventConnection {
    nodes {
      date
      id
      ...EventFragment
    }
  }
`
